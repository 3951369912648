import { EvaluationLayoutService } from "@puntaje/shared/cui"
import { Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { EvaluacionMultipleInstancia } from "@puntaje/puntaje/api-services"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State, GetItems } from "@puntaje/puntaje/store"
import { AppConfig, TitleService } from "@puntaje/shared/core"

declare const config: AppConfig
@Component({
    templateUrl: "resultados_evaluacion_multiple.component.html",
    styleUrls: ["./resultados_evaluacion_multiple.component.scss"]
})
export class ResultadosEvaluacionMultipleComponent implements OnInit, OnDestroy {
    evaluacionMultipleInstanciaId: number
    evaluacionMultipleInstancia: EvaluacionMultipleInstancia
    asignaturaEvaluacionTiposIds: number[]
    sub: Subscription
    listaAsignaturas: Asignatura[] = []
    plataformaNombre = config.plataforma.name
    @Output() onEvaluacionReady: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected store: Store<State>,
        protected evaluationLayoutService: EvaluationLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.evaluaciones_multiples.resultados")
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionMultipleInstanciaId = +params["id"]
        })

        this.evaluationLayoutService.setOptions({ contenidoLateralXL: true, borderedColumns: true, hideTitle: true })
    }

    onEvaluacionMultipleInstanciaReady(evaluacionMultipleInstancia) {
        this.evaluacionMultipleInstancia = evaluacionMultipleInstancia
        this.evaluacionMultipleInstancia.evaluacion_instancias =
            this.evaluacionMultipleInstancia.evaluacion_instancias.sort((a, b) => a.id - b.id)
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }

    setCurrentEvaluacion(evaluacionInstancia) {
        this.store.dispatch(new GetItems({ evaluacion: evaluacionInstancia.evaluacion }))
    }

    onListaAsignaturasChange(lista: Asignatura[]) {
        this.listaAsignaturas = lista
    }

    getTabsTitle(index) {
        if (this.plataformaNombre === "Sanisidoro") {
            return this.listaAsignaturas[index]?.abreviacion
        } else {
            return `Parte ${index + 1}`
        }
    }
}
