<el-topside>
    <evaluacion-tiempo
        *ngIf="evaluacion && loadClock && !showInstruccion && !showWait"
        [evaluacion]="evaluacion"
        (onTimeUp)="onTimeup()"
        (onReady)="onTiempoReady($event)"
        [enableToggle]="false"
        [enableFixed]="false"
        [animateOnStart]="false"
    ></evaluacion-tiempo>
    <div class="fix-position" *ngIf="enableSecuencial && !checkingEvaluacion">
        <widget-evaluacion-tiempo
            [invisibleClock]="!evaluacion || evaluacion.instrumento.tiempo == 0"
            [enableToggle]="true"
            [enableFixed]="false"
            [animateOnStart]="true"
            [collapseOnMobile]="true"
        ></widget-evaluacion-tiempo>
    </div>
</el-topside>

<loading-layout>
    <progreso-preguntas-evaluacion-secuencial
        [class.hidden]="hideProgreso"
        class="theme-cb"
    ></progreso-preguntas-evaluacion-secuencial>

    <ng-container *ngIf="showInstruccion">
        <div class="instruccion">
            <h2>Instrucciones</h2>
            <p>Estimado estudiante,</p>
            <p>
                A continuación, encontrarás una serie de instrucciones importantes para tu presentación del Simulacro
                Saber Pro en modalidad digital.
            </p>
            <ul>
                <li>
                    Recuerda que durante la prueba no está permitido utilizar materiales de consulta como libros, notas,
                    ni dispositivos adicionales (por ejemplo, celulares).
                </li>
                <li>
                    Para navegar utiliza las opciones de la plataforma para avanzar entre preguntas y revisar tus
                    respuestas.
                </li>
                <li>
                    El simulacro prueba tiene un tiempo determinado total de 3 horas y para cada módulo tendrás 45
                    minutos. El reloj será visible en la pantalla para que puedas estar al tanto de cuánto tiempo te
                    queda para completar cada módulo.
                </li>
                <li>
                    Si experimentas un corte de conexión a Internet, la plataforma generalmente te permitirá reconectar
                    retomando desde donde estabas, aunque el tiempo sigue corriendo.
                </li>
                <li>
                    Una vez hayas respondido todas las preguntas, asegúrate de revisar todas las respuestas antes de
                    finalizar la prueba y cada módulo. No olvides que, una vez finalizado el simulacro, no podrás
                    realizar cambios en tus respuestas. El sistema no detecta preguntas no respondidas, al marcar la
                    opción en la parte superior, la pregunta respondida se pintará de color azul y las que no se han
                    respondido no se pintará de ningún color.
                </li>
                <li>
                    Cuando termines el simulacro, asegúrate revisar que todas las preguntas estén respondidas, después,
                    haz clic en el botón «Finalizar este módulo». Aparecerá una ventana de confirmación para que
                    verifiques que has completado todos los módulos.
                </li>
                <li>
                    Al completar el simulacro, el sistema te indicará que la prueba ha sido enviada con éxito. Tus
                    resultados podrán verse de manera simultánea.
                </li>
            </ul>
            <p style="text-align: center">
                <i>¡Buena suerte en tu simulacro Saber Pro!</i>
            </p>
        </div>
        <div class="btn-bar">
            <button class="btn btn-default btn_style" (click)="next()">Continuar</button>
        </div>
    </ng-container>

    <ng-container *ngIf="showWait">
        <div class="waitMessage instruccion">
            <p>Descanse un momento antes de continuar con la siguiente parte.</p>
            <p>El tiempo comenzará a contabilizarse a penas presione el botón continuar.</p>
            <div class="btn-bar">
                <button class="btn btn-default btn_style" (click)="next()">Continuar</button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="showTimeup">
        <div *ngIf="evaluaciones.length - 1 != evaluacionIndex" class="instruccion">
            <p>Se terminó el tiempo. En unos segundos serás redireccionado a la siguiente parte.</p>
        </div>
        <div *ngIf="evaluaciones.length - 1 == evaluacionIndex" class="instruccion">
            <p>Se terminó el tiempo. En unos segundos la evaluación terminará.</p>
        </div>
    </ng-container>
    <evaluacion-secuencial
        [class.hidden]="showTimeup"
        *ngIf="!showInstruccion && !showWait"
        [evaluacionId]="evaluacionId"
        [evaluacionTiempo]="evaluacionTiempo"
        (onReadyEvaluacion)="onReadyEvaluacion($event)"
        (onLoadClockChange)="onLoadClockChange($event)"
        (evaluacionEntregada)="onEvaluacionEntregada($event)"
        [enableBtnMarkAsUncertain]="enableBtnMarkAsUncertain"
        [enablePreguntasId]="false"
        class="with-instructions theme-cb"
        [completarButton]="completarButton"
    ></evaluacion-secuencial>
</loading-layout>
